import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Language from 'components/Language';
import './styles.scss';
import querystring from 'querystring';
import { CancelToken } from 'axios';
import { getUser } from 'data/user/actions';
import { API, errorHandler } from 'services';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';

const LanguageSwitcher = ({ isLoggedIn, language }) => {
    const cancelToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const handleClick = () => {
        if (!isLoggedIn) {
            localStorage.setItem('language', language === 'pl' ? 'en' : 'pl');
            window.location.reload(false);
        } else {
            setIsLoading(true);
            cancelToken.current = CancelToken.source();
            API.put('/me', querystring.stringify({ language: language === 'pl' ? 'en' : 'pl' }), {
                cancelToken: cancelToken.current.token,
            })
                .then(() => {
                    window.location.reload(false);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setError(err);
                        setIsLoading(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    if (isLoading) {
        return <div className="LanguageSwitcher"><SmallLoader /></div>;
    }

    return (
        <div className="LanguageSwitcher">
            {error && <Error errors={error} />}
            <Language currentLang={language} clickHandler={() => { handleClick(); }} />
        </div>
    );
};

LanguageSwitcher.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { isLoggedIn } = user;
    const lang = localStorage.getItem('language') ?? 'en';
    const language = isLoggedIn && user.user ? user.user.language : lang;

    return {
        isLoggedIn,
        language,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
