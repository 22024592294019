import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { translations } from 'services';

const SingleLicenceMenu = ({ type, deleteVisible, isSubscriptions }) => (
    <div className="SingleLicenceMenu">
        <ul className="SingleLicenceMenu__menu">
            <li className="SingleLicenceMenu__menu__single">
                <NavLink
                    className="SingleLicenceMenu__link"
                    to={`/licences/${type}`}
                    exact
                >
                    {translations('front.admin.subscriptions.users_list')}
                </NavLink>
            </li>
            {
                type !== 'basic' && (
                    <li className="SingleLicenceMenu__menu__single">
                        <NavLink
                            className="SingleLicenceMenu__link"
                            to={`/licences/${type}/subscriptions`}
                            exact
                        >
                            {translations('front.admin.subscriptions.subs_list')}
                        </NavLink>
                    </li>
                )
            }
        </ul>
        <div className="SingleLicenceMenu__actions">
            {deleteVisible && (
                <button
                    type="button"
                    onClick={() => {
                        const event = new Event('ta-users-to-unassign');
                        document.dispatchEvent(event);
                    }}
                    className="SingleLicenceMenu__cancel"
                >
                    {translations('front.general.delete')}
                    <span className="tooltip">{translations('front.admin.unassign_tooltip')}</span>
                </button>
            )}
            {type !== 'basic' && <NavLink to={`/licences/checkout/${type}`} exact className="SingleLicenceMenu__buy">{translations('front.admin.buy')}</NavLink>}
            {!isSubscriptions && <NavLink to={`/licences/${type}/assign`} className="SingleLicenceMenu__connect">{translations('front.admin.assign')}</NavLink>}
        </div>
    </div>
);

SingleLicenceMenu.propTypes = {
    type: PropTypes.string.isRequired,
    deleteVisible: PropTypes.bool,
    isSubscriptions: PropTypes.bool,
};

SingleLicenceMenu.defaultProps = {
    deleteVisible: false,
    isSubscriptions: false,
};

export default SingleLicenceMenu;
