import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import Page from 'components/Page';
import { translations } from 'services';
import { getLicencesCount } from 'data/licencesCount/actions';
import SimpleLicence from 'components/SimpleLicence';
import SmallLoader from 'components/SmallLoader';
import './styles.scss';
import Error from 'components/Error';
import NotFound from 'pages/NotFound';
import SingleLicenceMenu from './components/SingleLicenceMenu';
import SingleLicenceUsers from './components/SingleLicenceUsers';
import SingleLicenceSubscriptions from './components/SingleLicenceSubscriptions';
import SingleLicenceAssign from './components/SingleLicenceAssign';

const SingleLicence = ({
    licences, licencesError, licencesLoading, getLicences, isSubscriptions, isAssign,
}) => {
    const { type } = useParams();
    const [counter, setCounter] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [unassignLoading, setUnAssignLoading] = useState(false);

    useEffect(() => {
        if (licences.length === 0) {
            getLicences();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (licences.length) {
            licences.forEach((licence) => {
                if (licence.id === type) {
                    setCounter(licence);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licences, type]);

    if (type !== 'basic' && type !== 'standard' && type !== 'premium') {
        return <NotFound />;
    }

    return (
        <div className="SingleLicence">
            <Page top>
                <PageHeader link="/licences">
                    {translations('front.admin.licences_page.licence')}
                    {' '}
                    {translations(`front.admin.licences_page.licence_type.${type}`)}
                </PageHeader>
                {counter !== false && !licencesLoading && (
                    <SimpleLicence
                        subscriptions={counter.subscriptions_count}
                        users={counter.users_count}
                    />
                )}
                {unassignLoading && <div className="loading-wrapper"><SmallLoader dark /></div> }
                <div style={{ display: unassignLoading ? 'none' : 'block' }}>
                    {licencesLoading && <div className="SingleLicence__licences-loader"><SmallLoader dark /></div>}
                    {licencesError && <Error errors={licencesError} />}
                    <SingleLicenceMenu
                        type={type}
                        deleteVisible={deleteVisible}
                        isSubscriptions={isSubscriptions}
                    />
                    {!isSubscriptions && (
                        <SingleLicenceUsers
                            trigger={(visible) => { setDeleteVisible(visible); }}
                            triggerLoading={(l) => { setUnAssignLoading(l); }}
                        />
                    )}
                    {isSubscriptions && (
                        <SingleLicenceSubscriptions />
                    )}
                    {isAssign && <SingleLicenceAssign />}
                </div>
            </Page>
        </div>
    );
};

SingleLicence.propTypes = {
    licences: PropTypes.arrayOf(PropTypes.object).isRequired,
    licencesLoading: PropTypes.bool.isRequired,
    licencesError: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
    getLicences: PropTypes.func.isRequired,
    isSubscriptions: PropTypes.bool,
    isAssign: PropTypes.bool,
};

SingleLicence.defaultProps = {
    isSubscriptions: false,
    isAssign: false,
};

const mapStateToProps = (state) => {
    const { licencesCount } = state;

    return {
        licences: licencesCount.licences,
        licencesLoading: licencesCount.loading,
        licencesError: licencesCount.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getLicences: () => dispatch(getLicencesCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleLicence);
