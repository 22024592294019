const mixed = {
    default: 'Wartość tego pola jest niepoprawna',
    required: 'To pole jest wymagane',
    // eslint-disable-next-line no-template-curly-in-string
    oneOf: 'To pole musi mieć jedną z następujących wartości: ${values}',
    // eslint-disable-next-line no-template-curly-in-string
    notOneOf: 'To pole nie może mieć jednej z następujących wartości: ${values}',
    // eslint-disable-next-line no-template-curly-in-string
    defined: '${path} must be defined',
};

const string = {
    // eslint-disable-next-line no-template-curly-in-string
    length: 'To pole musi mieć dokładnie ${length} znaków',
    // eslint-disable-next-line no-template-curly-in-string
    min: 'To pole musi mieć conajmniej ${min} znaków',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'To pole może mieć conajwyej ${max} znaków',
    // eslint-disable-next-line no-template-curly-in-string
    matches: 'To pole musi pasować do następującego wzorca: "${regex}"',
    email: 'To pole musi być poprawnym adresem email',
    url: 'To pole musi być poprawnym adresem URL',
    uuid: 'To pole musi być poprawnym identyfikatorem UUID',
    trim: 'To pole musi być tekstem bez spacji na początku i na końcu',
    lowercase: 'To pole może mieć tylko małe litery',
    uppercase: 'To pole może mieć tylko wielkie litery',
};

const number = {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'To pole musi być liczbą większą lub równą ${min}',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'To pole musi być liczą mniejsa lub równą ${max}',
    // eslint-disable-next-line no-template-curly-in-string
    lessThan: 'To pole musi być liczbą mniejszą od ${less}',
    // eslint-disable-next-line no-template-curly-in-string
    moreThan: 'To pole musi być liczbą więszką od ${more}',
    positive: 'To pole musi być liczbą dodatnią',
    negative: 'To pole musi być liczbą ujemną',
    integer: 'To pole musi być liczbą całkowitą',
};

const date = {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'To pole musi zawierać datę późniejszą niż ${min}',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'To pole musi zawierać datę wcześniejszą niż ${max}',
};

const boolean = {
    // eslint-disable-next-line no-template-curly-in-string
    isValue: 'To pole musi mieć wartość ${value}',
};

const object = {
    // eslint-disable-next-line no-template-curly-in-string
    noUnknown: 'To pole zawiera nieznane klucze: ${unknown}',
};

const array = {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'To pole musi zawierać conajmniej ${min} elementów',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'To pole może zawierać conajwyżej ${max} elementów',
    // eslint-disable-next-line no-template-curly-in-string
    length: 'To pole musi mieć dokładnie ${length} elementów',
};

export default Object.assign(Object.create(null), {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
});
