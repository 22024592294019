export default () => {
    let redirectUrl = 'https://app.transassist.eu';

    if (window.location.host === 'localhost:3000'
        || window.location.host === 'admin-dev.transassist.eu') {
        redirectUrl = 'https://app-dev.transassist.eu';
    }

    if (window.location.host === 'admin-staging.transassist.eu') {
        redirectUrl = 'https://app-staging.transassist.eu';
    }

    return redirectUrl;
};
