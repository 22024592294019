import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const Error = ({ errors }) => {
    if (!errors) {
        return <></>;
    }
    let errorHeader = false;
    const errorsList = [];
    if (typeof errors.response !== 'undefined') {
        if (typeof errors.response.data !== 'undefined') {
            if (typeof errors.response.data.message !== 'undefined' && errors.response.data.message) {
                errorHeader = errors.response.data.message;
            } else if (typeof errors.message !== 'undefined' && errors.message) {
                errorHeader = errors.message;
            }
            if (typeof errors.response.data.errors !== 'undefined') {
                Object.keys(errors.response.data.errors).forEach((key) => {
                    errorsList.push({
                        keyName: key,
                        val: errors.response.data.errors[key]
                    });
                });
            }
        }
    }

    const errorsMessages = errorsList.map((error) => (
        <p className="Error__single" key={error.keyName}>{error.val}</p>
    ));


    return (
        <div className="Error">
            {errorHeader ? <p className="Error__header">{errorHeader}</p> : ''}
            {errorsMessages}
        </div>
    );
};

Error.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
};

Error.defaultProps = {
    errors: false
};

export default Error;
