import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const LicenceLine = ({ subscriptions, users }) => {
    let width = subscriptions > 0 ? ((100 * users) / subscriptions) : 0;

    if (width > 100) {
        width = 100;
    }

    width = `${width}%`;
    return (
        <div className="LicenceLine">
            <div className="LicenceLine__level" style={{ width }} />
        </div>
    );
};

LicenceLine.propTypes = {
    subscriptions: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
};

export default LicenceLine;
