import Axios from 'axios';

const errorHandler = (error, callback) => {
    // eslint-disable-next-line no-empty
    if (Axios.isCancel(error)) {
    } else {
        console.error(error);
        callback();
    }
};

export default errorHandler;
