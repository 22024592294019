const translations = {
    loading: 'Wczytywanie',
    loading_data: 'Wczytywanie danych...',
    validate_email: 'To nie jest poprawny adres email',
    validate_required: 'To pole jest wymagane',
    validate_the_same: 'Oba hasła muszą być takie same',
    sign_in: 'Zaloguj się',
    sign_up__invitation: 'Nie posiadasz konta?',
    sign_up: 'Zarejestruj się!',
    forgot_password: 'Nie pamiętasz hasła?',
    error_occured: 'Wystąpił błąd!',
    activation_token_broken: 'Token aktywacyjny jest niepoprawny.',
    return_to_login: 'Powrót do logowania',
    activation_success: 'Dziękujemy! Twoje konto jest teraz aktywne.',
    forgot_password_submit: 'Zresetuj hasło',
    password: 'Hasło',
    password_confirmation: 'Powtórz hasło',
    email: 'E-mail',
    remember_me: 'Zapamiętaj mnie',
    welcome: 'Witaj!',
    log_in_to_access: 'Zaloguj się aby rozpocząć',
    password_forgot_message: 'Nie możesz się zalogować? Wpisz swój adres email aby zresetować swoje hasło!',
    back_to: 'Wróć do',
    sign_in_page: 'logowania',
    select: 'Wybierz...',
    go_back_ta: 'Wróć do platformy Trans Assist',
};
export default translations;
