import React from 'react';
import './styles.scss';
import { translations } from '../../services';

const Footer = () => {
    const menu = [
        {
            href: 'https://www.transassist.eu/polityka-prywatnosci/',
            label: translations('front.admin.footer.menu.privacy'),
        },
        {
            href: 'https://www.transassist.eu/regulamin/',
            label: translations('front.admin.footer.menu.rules'),
        }
    ];

    return (
        <div className="Footer">
            <div className="wrapper Footer__wrapper">
                <div className="Footer__content">
                    Copyright © 2021 Trans Assist. All rights reserved
                </div>
                <ul className="Footer__menu">
                    {menu.map((elm) => (
                        <li className="Footer__menu__element" key={elm.href}>
                            <a
                                className="Footer__menu__link"
                                href={elm.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {elm.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Footer;
