import { API } from 'services';
import { CancelToken } from 'axios';

import {
    LICENCES_COUNT_SUCCESS,
    LICENCES_COUNT_REQUEST,
    LICENCES_COUNT_ERROR,
} from './constants';

export function startLicencesCountRequest(payload) {
    return { type: LICENCES_COUNT_REQUEST, payload };
}

export function licencesCountSuccess(payload) {
    return { type: LICENCES_COUNT_SUCCESS, payload };
}

export function licencesCountError(payload) {
    return { type: LICENCES_COUNT_ERROR, payload };
}

export function getLicencesCount() {
    return (dispatch) => {
        const source = CancelToken.source();
        dispatch(startLicencesCountRequest(source));

        return API.get('/admin-panel/subscriptions/count', {
            cancelToken: source.token,
        })
            .then((response) => {
                if (response.status === 200 && response.data.data && response.data.data.length) {
                    return dispatch(licencesCountSuccess(response.data.data));
                }
                return dispatch(licencesCountSuccess([]));
            })
            .catch((error) => dispatch(licencesCountError(error)));
    };
}
