import {
    USER_ERROR,
    USER_REQUEST,
    USER_SUCCESS,
    USER_LOGOUT,
    USER_LOG_IN_REQUEST,
    SET_USER_NOT_LOGGED_IN,
    ACTIVATION_SUCCESS,
    ACTIVATION_ERROR,
    ACTIVATION_REQUEST,
} from './constants';

export const initialGeneralState = {
    user: {},
    isLoggedIn: false,
    loading: false,
    error: false,
    activated: null,
    activationError: false,
    activationLoading: false,
    permissions: [],
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case USER_REQUEST:
        newState.isLoggedIn = false;
        newState.loading = true;
        newState.permissions = [];
        return newState;
    case ACTIVATION_REQUEST:
        newState.activated = null;
        newState.activationLoading = true;
        return newState;
    case USER_SUCCESS:
        newState.isLoggedIn = true;
        newState.loading = false;
        newState.user = action.payload;
        if (action.permissions !== false) {
            newState.permissions = action.permissions;
        }
        return newState;
    case USER_ERROR:
        newState.isLoggedIn = false;
        newState.loading = false;
        newState.user = null;
        newState.error = action.payload;
        newState.permissions = [];
        return newState;
    case USER_LOGOUT:
        newState.isLoggedIn = false;
        newState.loading = false;
        newState.user = null;
        newState.error = null;
        return newState;
    case USER_LOG_IN_REQUEST:
        newState.isLoggedIn = false;
        newState.loading = true;
        return newState;
    case SET_USER_NOT_LOGGED_IN:
        newState.isLoggedIn = false;
        newState.loading = false;
        newState.user = null;
        newState.error = null;
        return newState;
    case ACTIVATION_SUCCESS:
        newState.activated = true;
        newState.activationLoading = false;
        newState.activationError = false;
        return newState;
    case ACTIVATION_ERROR:
        newState.activated = false;
        newState.activationLoading = false;
        newState.activationError = action.payload;
        return newState;
    default:
        return state;
    }
};
