import React, { useEffect, useRef, useState } from 'react';
import {
    API, classNames, errorHandler, translations
} from 'services';
import { CancelToken } from 'axios';
import { Formik, Field, Form } from 'formik';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import querystring from 'querystring';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import Error from 'components/Error';
import './styles.scss';

const Checkout = () => {
    const cancelToken = useRef(null);
    const formRef = useRef(null);
    const { type } = useParams();
    const [qty, setQty] = useState(1);
    const [checked, setChecked] = useState(false);
    const payment = type === 'standard' ? 9.90 : 59.90;
    const total = qty * payment;
    const paymentInfo = `${payment.toFixed(2)}€ / ${translations('front.admin.checkout.month')}`;
    const btnEnabled = ((qty > 0) && checked);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const timeout = useRef(null);

    const checkValues = () => {
        let toSet = 0;
        if (formRef.current.values.qty > 0
            && !Number.isNaN(parseInt(formRef.current.values.qty, 10))) {
            toSet = parseInt(formRef.current.values.qty, 10);
        }
        setQty(toSet);
    };

    const handleCheck = () => {
        setChecked((v) => !v);
    };

    const handleSubmit = () => {
        cancelToken.current = CancelToken.source();
        setLoading(true);
        API.post('/admin-panel/subscriptions', querystring.stringify({
            plan_id: type,
            quantity: qty,
        }), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                setLoading(false);
                if (response.data.data && response.data.data.url && response.data.data.url.length) {
                    window.location.href = response.data.data.url;
                }
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setLoading(false);
                    setError(err);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }, []);

    return (
        <div className="Checkout">
            <Page>
                <PageHeader>{translations('front.admin.checkout.header')}</PageHeader>
                <div className="Checkout__content">
                    <div className="Checkout__content__licence">
                        <div className="Checkout__content__licence__type">
                            <div className="Checkout__content__header">{translations('front.admin.checkout.licence')}</div>
                            <div className={classNames('Checkout__content__copy', `Checkout__content__copy--${type}`)}>{type}</div>
                        </div>
                        <div className="Checkout__content__licence__quantity">
                            <div className="Checkout__content__header">{translations('front.admin.checkout.quantity')}</div>
                            <div className="Checkout__content__copy">
                                <Formik
                                    initialValues={{ qty }}
                                    innerRef={formRef}
                                    onSubmit={(values) => { setQty(values.qty); }}
                                >
                                    {() => (
                                        <Form onChange={() => {
                                            setTimeout(checkValues, 50);
                                        }}
                                        >
                                            <Field
                                                name="qty"
                                                type="number"
                                                min={1}
                                                className="Checkout__content__qty"
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <div className="Checkout__content__licence__total">
                            <div className="Checkout__content__header">{translations('front.admin.checkout.payment')}</div>
                            <div className="Checkout__content__copy">{paymentInfo}</div>
                        </div>
                        <div className="Checkout__content__licence__btns">
                            <button
                                disabled={qty < 2 || loading}
                                className="Checkout__content__licence__btns__minus"
                                type="button"
                                onClick={() => {
                                    formRef.current.setFieldValue('qty', qty - 1);
                                    setQty((q) => q - 1);
                                }}
                            >
                                -
                            </button>
                            <button
                                disabled={loading}
                                className="Checkout__content__licence__btns__plus"
                                type="button"
                                onClick={() => {
                                    formRef.current.setFieldValue('qty', qty + 1);
                                    setQty((q) => q + 1);
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="Checkout__content__accept">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>
                            <input
                                onChange={handleCheck}
                                checked={checked}
                                type="checkbox"
                                className="checkbox"
                            />
                            {/* eslint-disable-next-line react/no-danger */}
                            <span className="checkbox-span" dangerouslySetInnerHTML={{ __html: translations('front.admin.checkout.acceptance') }} />
                        </label>
                    </div>
                    <div className="Checkout__content__total">
                        {error && (
                            <div style={{ width: '100%' }}>
                                <Error errors={error} />
                            </div>
                        )}
                        <div className="Checkout__content__total__copy">
                            <span className="Checkout__content__total__copy--title">{translations('front.admin.checkout.total')}</span>
                            {` ${total.toFixed(2)}€ / ${translations('front.admin.checkout.month')}`}
                        </div>
                        <Button
                            withLoader
                            loading={loading}
                            type="button"
                            theme="blue"
                            disabled={!btnEnabled}
                            onClick={() => { handleSubmit(); }}
                        >
                            {translations('front.admin.checkout.submit')}
                            <span className="tooltip">{translations('front.admin.checkout.acceptance_message')}</span>
                        </Button>
                    </div>
                </div>
            </Page>
        </div>
    );
};

export default Checkout;
