import React from 'react';
import PropTypes from 'prop-types';
import { classNames, translations } from 'services';
import './styles.scss';

const LicenceStatus = ({ status }) => {
    const copy = translations(`front.admin.status.${status}`);

    return (
        <div className={classNames('LicenceStatus', status)}>
            {copy}
        </div>
    );
};

LicenceStatus.propTypes = {
    status: PropTypes.oneOf([
        'active',
        'inactive',
    ]).isRequired,
};

export default LicenceStatus;
