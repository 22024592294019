import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import Yup from 'services/translatedYup';
import defaultTranslation from 'services/defaultTranslations';
import Button from 'components/Button';
import Error from 'components/Error';
import FormField from 'components/FormField';
import BackToTransassist from 'components/BackToTransassist';
import { logIn } from 'data/user/actions';
import './styles.scss';

const Auth = ({ logUserIn, loading, error }) => {
    const lang = localStorage.getItem('language') ?? 'en';

    const trans = {
        email: defaultTranslation('email', lang),
        password: defaultTranslation('password', lang),
        remember_me: defaultTranslation('remember_me', lang),
        sign_in: defaultTranslation('sign_in', lang),
    };
    const defaultValues = {
        email: '',
        password: '',
        remember_me: false,
    };
    const rules = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        remember_me: Yup.bool(),
    });
    const handleSubmit = (values) => {
        logUserIn(values.email, values.password, values.remember_me);
    };
    return (
        <div className="Auth">
            {error && <Error errors={error} />}
            <Formik
                initialValues={defaultValues}
                onSubmit={(vals) => { handleSubmit(vals); }}
                validationSchema={rules}
            >
                {() => (
                    <Form>
                        <div className="Auth__group">
                            <div className="Auth__label">{trans.email}</div>
                            <FormField name="email" type="email" />
                        </div>
                        <div className="Auth__group">
                            <div className="Auth__label">{trans.password}</div>
                            <FormField name="password" type="password" />
                        </div>
                        <div className="Auth__group Auth__group--last">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label style={{ display: 'block' }}>
                                <Field name="remember_me" type="checkbox" className="Auth__checkbox checkbox" />
                                <span className="Auth__checkbox-span checkbox-span">{trans.remember_me}</span>
                            </label>
                        </div>
                        <Button type="submit" block large withLoader loading={loading} theme="blue">{trans.sign_in}</Button>
                    </Form>
                )}
            </Formik>
            <BackToTransassist center />
        </div>
    );
};

Auth.propTypes = {
    logUserIn: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]).isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        loading: user.loading,
        error: user.error ? user.error : false,
    };
};

const mapDispatchToProps = (dispatch) => ({
    logUserIn: (email, password, remember = false) => dispatch(logIn(email, password, remember)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
