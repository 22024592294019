import pl from './pl/index';
import en from './en/index';

const defaultTranslation = (keyName, lang = 'en') => {
    const getTranslation = (k, l) => {
        switch (l) {
        case 'en':
            if (typeof en[k] !== 'undefined') {
                return en[k];
            }
            break;
        case 'pl':
            if (typeof pl[k] !== 'undefined') {
                return pl[k];
            }
            break;
        default:
            return '';
        }

        if (l !== 'en') {
            if (typeof en[k] !== 'undefined') {
                return en[k];
            }
        }

        return '';
    };

    if (typeof keyName === 'undefined') {
        return '';
    }

    if (!lang) {
        return getTranslation(keyName, 'en');
    }

    return getTranslation(keyName, lang);
};

export default defaultTranslation;
