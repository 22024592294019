import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from 'components/Avatar';
import { NavLink } from 'react-router-dom';
import { getTransAssistUrl, translations } from 'services';
import Logout from 'stateful/Logout';

import './styles.scss';

const HeaderUser = ({ fullName, avatar }) => {
    const [clicked, setClicked] = useState(false);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setClicked(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    return (
        <div className="HeaderUser" ref={wrapperRef}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div
                role="button"
                className="HeaderUser__name"
                onClick={() => { setClicked((c) => !c); }}
            >
                {fullName}
            </div>
            <button type="button" className="HeaderUser__avatar" onClick={() => { setClicked((c) => !c); }}>
                <Avatar width={40} height={40} image={avatar} />
            </button>
            {clicked && (
                <ul className="HeaderUser__menu">
                    <li className="HeaderUser__menu--element"><Logout /></li>
                    <li className="HeaderUser__menu--element">
                        <a href={getTransAssistUrl()} className="HeaderUser__menu--link link-back">{translations('front.admin.back_to_app')}</a>
                    </li>
                    {false && (
                        <li className="HeaderUser__menu--element">
                            <NavLink to="/settings" className="HeaderUser__menu--link link-settings" exact>{translations('front.admin.settings.link')}</NavLink>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

HeaderUser.propTypes = {
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        fullName: `${user.user.name} ${user.user.surname}`,
        avatar: user.user.avatar && user.user.avatar.download_url ? user.user.avatar.download_url : '',
    };
};

export default connect(mapStateToProps, null)(HeaderUser);
