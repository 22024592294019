import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { classNames, translations } from 'services';
import { NavLink } from 'react-router-dom';
import LicenceLine from 'components/LicenceLine';

const Licence = ({ type, subscriptions, users }) => {
    let benefitsList = '';
    const benefitsHeader = <div className="Licence__benefits__header">{translations('front.admin.licences_page.licence_benefits')}</div>;

    if (type === 'standard') {
        benefitsList = (
            <div className="Licence__benefits">
                {benefitsHeader}
                <ul className="Licence__benefits__list">
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_ecmr')}</li>
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_esign')}</li>
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_monitoring')}</li>
                </ul>
            </div>
        );
    } else if (type === 'premium') {
        benefitsList = (
            <div className="Licence__benefits">
                {benefitsHeader}
                <ul className="Licence__benefits__list">
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_tablet')}</li>
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_internet')}</li>
                    <li className="Licence__benefits__list__single">{translations('front.admin.licences_page.licence_nav')}</li>
                </ul>
            </div>
        );
    }
    return (
        <div className={classNames('Licence', type, type !== 'basic' && subscriptions === 0 ? 'empty' : '')}>
            <div className="Licence__copy">{translations('front.admin.licences_page.licence')}</div>
            <div className="Licence__type">{translations(`front.admin.licences_page.licence_type.${type}`)}</div>
            <div className="Licence__details">
                <div className="Licence__copy Licence__copy--count">{translations('front.admin.licences_page.licence_count')}</div>
                <div className="Licence__limit">
                    {users}
                    {' '}
                    /
                    {' '}
                    {subscriptions}
                </div>
                <LicenceLine subscriptions={subscriptions} users={users} />
            </div>
            {benefitsList}
            {type !== 'basic' && subscriptions === 0 ? (
                <NavLink to={`/licences/checkout/${type}`} exact className="Licence__add">{translations('front.admin.buy')}</NavLink>
            ) : (
                <NavLink to={`/licences/${type}`} exact className="Licence__add">{translations('front.admin.licences_page.licence_add')}</NavLink>
            )}
        </div>
    );
};

Licence.propTypes = {
    type: PropTypes.string.isRequired,
    subscriptions: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
};

export default Licence;
