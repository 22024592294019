import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    classNames, translations
} from 'services';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import './styles.scss';
import Licence from 'components/Licence';
import { getLicencesCount } from 'data/licencesCount/actions';

const Licences = ({
    isLoading, error, licences, getLicences
}) => {
    useEffect(() => {
        if (licences.length === 0) {
            getLicences();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="Licences">
            <Page>
                <PageHeader>{translations('front.admin.menu.licenses')}</PageHeader>
                <div className={classNames('Licences__list', isLoading && 'loading')}>
                    {isLoading && <SmallLoader dark />}
                    {!isLoading && (
                        <>
                            {error && <Error errors={error} />}
                            <div className="Licences__list__list">
                                {licences.map((licence) => (
                                    <Licence
                                        key={licence.id}
                                        type={licence.id}
                                        subscriptions={licence.subscriptions_count}
                                        users={licence.users_count}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </Page>
        </div>
    );
};

Licences.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
    licences: PropTypes.arrayOf(PropTypes.object).isRequired,
    getLicences: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
    const { licencesCount } = state;

    return {
        licences: licencesCount.licences,
        isLoading: licencesCount.loading,
        error: licencesCount.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getLicences: () => dispatch(getLicencesCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Licences);
