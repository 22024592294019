import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { translations } from '../../services';

const PageHeader = ({ children, link }) => (
    <h1 className="PageHeader">
        {children}
        {link && <NavLink to={link} exact className="PageHeader__btn">{translations('front.general.close')}</NavLink>}
    </h1>
);

PageHeader.propTypes = {
    children: PropTypes.node.isRequired,
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};

PageHeader.defaultProps = {
    link: false,
};

export default PageHeader;
