import React, { useCallback, useState } from 'react';
import { isDevEnvironment } from 'services';
import style from './EnvSwitcher.module.scss';

const EnvSwitcher = () => {
    const apiVersion = localStorage.getItem('ta_api_version');
    const [usingApiVersion, setUsingApiVersion] = useState(apiVersion !== 'prod' ? 'dev' : 'prod');

    const switchApi = useCallback(() => {
        const newApiVersion = usingApiVersion === 'dev' ? 'prod' : 'dev';
        localStorage.setItem('ta_api_version', newApiVersion);
        setUsingApiVersion(newApiVersion);
        window.location.reload();
    }, [usingApiVersion]);

    if (!isDevEnvironment()) {
        return null;
    }

    return (
        <div className={style.EnvSwitcher}>
            <p>
                Używam API w wersji
                {' '}
                <strong>{usingApiVersion}</strong>
                <button type="button" className={style.Button} onClick={switchApi}>{`Zmień na ${usingApiVersion === 'dev' ? 'prod' : 'dev'}`}</button>
            </p>
        </div>
    );
};

export default EnvSwitcher;
