import React from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { translations } from 'services';

const HeaderMenu = () => {
    const elements = [
        {
            to: '/licences',
            copy: translations('front.admin.menu.licenses'),
        },
        {
            to: '/payments',
            copy: translations('front.admin.menu.payments'),
        },
    ];

    return (
        <ul className="HeaderMenu">
            {elements.map((element) => (
                <li className="HeaderMenu__element" key={element.to}>
                    <NavLink className="HeaderMenu__element--link" to={element.to}>{element.copy}</NavLink>
                </li>
            ))}
        </ul>
    );
};

export default HeaderMenu;
