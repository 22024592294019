import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Moment from 'moment';
import {
    API, classNames, errorHandler, translations
} from 'services';
import { getLicencesCount } from 'data/licencesCount/actions';
import SimpleLicence from 'components/SimpleLicence';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import { CancelToken } from 'axios';
import qs from 'qs';
import PaymentsInvoices from './components/PaymentsInvoices';
import './styles.scss';

const Payments = ({
    licences, licencesError, licencesLoading, getLicences
}) => {
    const cancelToken = useRef(null);
    const cancelTokenInfo = useRef(null);
    const useDev = ['localhost:3000', 'admin-tests-dev.transassist.eu'].includes(window.location.host);

    const [methodLoading, setMethodLoading] = useState(false);
    const [methodError, setMethodError] = useState(false);
    const [infoLoading, setInfoLoading] = useState(true);
    const [infoError, setInforError] = useState(false);
    const [owner, setOwner] = useState('---');
    const [amount, setAmount] = useState('---');
    const [payment, setPayment] = useState('---');
    const changePaymentMethod = () => {
        setMethodError(false);
        setMethodLoading(true);

        cancelToken.current = CancelToken.source();
        API.post('/admin-panel/change-payment-method', qs.stringify({
            frontend_url: `${useDev ? 'https://admin-dev.transassist.eu' : 'https://admin.transassist.eu'}/payments`
        }), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data
                        && response.data.data.url
                        && response.data.data.url.length) {
                        window.location.href = response.data.data.url;
                    }
                }
                setMethodLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setMethodError(error);
                    setMethodLoading(false);
                });
            });
    };

    useEffect(() => {
        cancelTokenInfo.current = CancelToken.source();
        API.get('/admin-panel/estimated', {
            cancelToken: cancelTokenInfo.current.token,
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200 && response.data.data) {
                    if (response.data.data.estimated_payment) {
                        if (response.data.data.estimated_payment.sum) {
                            setAmount(`${(0.01 * response.data.data.estimated_payment.sum).toFixed(2)}€`);
                        }
                        if (response.data.data.estimated_payment.date) {
                            setPayment(Moment(response.data.data.estimated_payment.date).format('LL'));
                        }
                    }
                    if (response.data.data.customer_info) {
                        const customerInfo = response.data.data.customer_info;
                        if (customerInfo.first_name
                            && customerInfo.first_name.length
                            && customerInfo.last_name
                            && customerInfo.last_name.length) {
                            setOwner(`${customerInfo.first_name} ${customerInfo.last_name}`);
                        } else if (customerInfo.name && customerInfo.name.length) {
                            setOwner(customerInfo.name);
                        }
                    }
                }
                setInfoLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setInfoLoading(false);
                    setInforError(err);
                });
            });
    }, []);

    useEffect(() => {
        if (licences.length === 0) {
            getLicences();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        if (cancelTokenInfo.current) {
            cancelTokenInfo.current.cancel();
        }
    }, []);

    return (
        <div className="Payments">
            <Page>
                <PageHeader>{translations('front.admin.menu.payments')}</PageHeader>
                <div className="Payments__content">
                    <div className={classNames('Payments__info', infoLoading && 'is-loading')}>
                        {!infoError && (
                            <>
                                <div className="Payments__info__single">
                                    <div className="Payments__info__header">{translations('front.admin.payments.freq')}</div>
                                    <div className="Payments__info__value">{translations('front.admin.payments.freq_monthly')}</div>
                                </div>
                                <div className="Payments__info__single">
                                    <div className="Payments__info__header">{translations('front.admin.payments.next')}</div>
                                    <div className="Payments__info__value">{payment}</div>
                                </div>
                                <div className="Payments__info__single">
                                    <div className="Payments__info__header">{translations('front.admin.payments.amount')}</div>
                                    <div className="Payments__info__value">{amount}</div>
                                </div>
                                <div className="Payments__info__single">
                                    <div className="Payments__info__header">{translations('front.admin.payments.method')}</div>
                                    <div className="Payments__info__value">
                                        {owner !== '---' && (
                                            <>
                                                {methodError && <Error errors={methodError} />}
                                                {!methodLoading && (
                                                    <button type="button" onClick={() => { changePaymentMethod(); }} className="Payments__info__value--btn">
                                                        {translations('front.admin.payments.method_change')}
                                                    </button>
                                                )}
                                                {methodLoading && <SmallLoader dark />}
                                            </>
                                        )}
                                        {owner === '---' ? '---' : ''}
                                    </div>
                                </div>
                                <div className="Payments__info__single">
                                    <div className="Payments__info__header">{translations('front.admin.payments.owner')}</div>
                                    <div className="Payments__info__value">{owner}</div>
                                </div>
                            </>
                        )}
                        {infoLoading && <SmallLoader dark />}
                        {infoError && <Error errors={infoError} />}
                    </div>
                    <div className="Payments__counters">
                        {licencesError && <Error errors={licencesError} />}
                        {licencesLoading && <SmallLoader dark />}
                        {!licencesLoading && licences.length > 0 && licences.map((licence) => (
                            <SimpleLicence
                                key={licence.id}
                                type={licence.id}
                                subscriptions={licence.subscriptions_count}
                                users={licence.users_count}
                            />
                        ))}
                    </div>
                    <PaymentsInvoices />
                </div>
            </Page>
        </div>
    );
};

Payments.propTypes = {
    licences: PropTypes.arrayOf(PropTypes.object).isRequired,
    licencesLoading: PropTypes.bool.isRequired,
    licencesError: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
    getLicences: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { licencesCount } = state;

    return {
        licences: licencesCount.licences,
        licencesLoading: licencesCount.loading,
        licencesError: licencesCount.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getLicences: () => dispatch(getLicencesCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
