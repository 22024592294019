import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FormField = ({ error, ...props }) => (
    <div className="form-field__error" {...props} title={error}>
        {error}
    </div>
);

FormField.propTypes = {
    error: PropTypes.string.isRequired,
};

export default FormField;
