import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Avatar = ({ image, width, height }) => {
    const styles = {
        width: `${width}px`,
        height: `${height}px`,
    };

    if (image && image.length) {
        styles.backgroundImage = `url(${image})`;
    }

    return <div className="Avatar" style={styles} />;
};

Avatar.propTypes = {
    image: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

Avatar.defaultProps = {
    image: '',
    width: 48,
    height: 48,
};

export default Avatar;
