import React, {
    useEffect, useState, useRef, useCallback
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CancelToken } from 'axios';
import {
    API, classNames, errorHandler, translations
} from 'services';
import { Field, Form, Formik } from 'formik';
import Avatar from 'components/Avatar';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import './styles.scss';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import { getLicencesCount } from 'data/licencesCount/actions';

const SingleLicenceUsers = ({ trigger, triggerLoading, getLicences }) => {
    const { type } = useParams();
    const cancelToken = useRef(null);
    const cancelTokenUnassign = useRef(null);
    const perPage = 50;
    const reference = useRef(null);
    const timer = useRef(0);
    const [users, setUsers] = useState([]);
    const [ids, setIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [triggerCounter, setTriggerCounter] = useState(0);
    const initialValues = {
        users: [],
    };

    const unassignTrigger = useCallback(() => {
        if (reference.current.values.users.length) {
            triggerLoading(true);
            const vals = {
                user_ids: reference.current.values.users,
                plan_id: type,
            };
            setError(false);
            cancelTokenUnassign.current = CancelToken.source();
            API.post('/admin-panel/subscriptions/unassign-users-multi', qs.stringify(vals), {
                cancelToken: cancelTokenUnassign.current.token,
            })
                .then(() => {
                    triggerLoading(false);
                    setTriggerCounter((v) => v + 1);
                    getLicences();
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        triggerLoading(false);
                        setError(err);
                    });
                });
        }
    }, [getLicences, triggerLoading, type]);

    const handleTrigger = useCallback(() => {
        setTriggerCounter((v) => v + 1);
    }, []);

    const handleScroll = useCallback(() => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if (currentPage < maxPage && !isLoading) {
                setIsLoading(true);
                cancelToken.current = CancelToken.source();
                API.get(`/users?page[size]=${perPage}&page[number]=${currentPage + 1}&filter[subscription_id]=${type}`, {
                    cancelToken: cancelToken.current.token,
                })
                    .then((response) => {
                        if (response.status === 200
                            && response.data.data && response.data.data.length) {
                            setUsers((oldUsers) => oldUsers.concat(response.data.data));
                            const idsToSet = [];
                            response.data.data.forEach((user) => {
                                idsToSet.push(`${user.id}`);
                            });
                            setIds((oldIds) => oldIds.concat(idsToSet));
                        }
                        if (response.data.meta) {
                            setCurrentPage(response.data.meta.current_page);
                            setMaxPage(response.data.meta.last_page);
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setError(err);
                            setIsLoading(false);
                        });
                    });
            }
        }
    }, [currentPage, isLoading, maxPage, type]);

    const handleChange = () => {
        timer.current = setTimeout(() => {
            try {
                if (reference.current.values.users.length) {
                    trigger(true);
                } else {
                    trigger(false);
                }
            } catch (e) {
                console.error(e);
                trigger(false);
            }
        }, 50);
    };

    const triggerLine = (id) => {
        if (reference.current.values.users.includes(id)) {
            const newArray = [];
            reference.current.values.users.forEach((user) => {
                if (user !== id) {
                    newArray.push(user);
                }
            });
            reference.current.setFieldValue('users', newArray);
        } else {
            const newArray = [...reference.current.values.users, id];
            reference.current.setFieldValue('users', newArray);
        }
        handleChange();
    };

    const handleToggle = (values) => {
        if (reference.current) {
            if (values.users.length > 0) {
                if (values.users.length !== users.length) {
                    reference.current.setFieldValue('users', ids);
                } else {
                    reference.current.setFieldValue('users', []);
                }
            } else {
                reference.current.setFieldValue('users', ids);
            }
            handleChange();
        }
    };

    useEffect(() => {
        const source = CancelToken.source();
        setIsLoading(true);
        API.get(`/users?page[size]=${perPage}&filter[subscription_id]=${type}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    setUsers(response.data.data);
                    const idsToSet = [];
                    response.data.data.forEach((user) => {
                        idsToSet.push(`${user.id}`);
                    });
                    setIds(idsToSet);
                }
                setIsLoading(false);
                if (response.data.meta) {
                    if (response.data.meta.last_page > maxPage) {
                        setMaxPage(response.data.meta.last_page);
                        handleScroll();
                    }
                }
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerCounter]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('ta-users-to-reload', handleTrigger);
        document.addEventListener('ta-users-to-unassign', unassignTrigger);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('ta-users-to-reload', handleTrigger);
            document.removeEventListener('ta-users-to-unassign', unassignTrigger);
        };
    }, [handleScroll, handleTrigger, unassignTrigger]);

    useEffect(() => () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
    }, []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        if (cancelTokenUnassign.current) {
            cancelTokenUnassign.current.cancel();
        }
    }, []);

    return (
        <div className="SingleLicenceUsers">
            {error && <Error errors={error} />}
            <Formik
                innerRef={reference}
                initialValues={initialValues}
                onSubmit={() => {}}
            >
                {({ values }) => (
                    <Form onChange={() => { handleChange(); }}>
                        {users.length === 0 && (
                            <div className="empty-table">{translations('front.admin.empty.licence')}</div>
                        )}
                        {users.length > 0 && (
                            <div className="table-outer">
                                <table className="table">
                                    <thead className="table__head">
                                        <tr>
                                            <th scope="col" className="table__checkbox">
                                                <div className="table__th">
                                                    {users.length > 0 && (
                                                        <button
                                                            type="button"
                                                            className={classNames(
                                                                'checkbox-button',
                                                                (values.users.length === users.length) && 'active'
                                                            )}
                                                            onClick={() => {
                                                                handleToggle(values);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </th>
                                            <th scope="col" className="table__name">
                                                <div className="table__th">
                                                    {translations('front.admin.columns.name')}
                                                </div>
                                            </th>
                                            <th scope="col" className="table__email">
                                                <div className="table__th">
                                                    {translations('front.admin.columns.email')}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="table__body">
                                        {users.map((user) => (
                                            <tr key={user.id} onClick={() => { triggerLine(`${user.id}`); }} style={{ cursor: 'pointer' }}>
                                                <td className="table__checkbox">
                                                    <Field
                                                        checked={values.users.includes(`${user.id}`)}
                                                        name="users"
                                                        type="checkbox"
                                                        className="checkbox"
                                                        value={user.id}
                                                    />
                                                    <span className="checkbox-span" />
                                                </td>
                                                <td className="table__name">
                                                    <div className="table__name__wrapper">
                                                        <Avatar
                                                            width={48}
                                                            height={48}
                                                            image={user.avatar && user.avatar.download_url ? user.avatar.download_url : ''}
                                                        />
                                                        {user.name}
                                                        {' '}
                                                        {user.surname}
                                                    </div>
                                                </td>
                                                <td className="table__email">{user.email}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            {isLoading && (
                <div className="SingleLicenceUsers__loading">
                    <SmallLoader dark />
                </div>
            )}
        </div>
    );
};

SingleLicenceUsers.propTypes = {
    trigger: PropTypes.func.isRequired,
    triggerLoading: PropTypes.func.isRequired,
    getLicences: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getLicences: () => dispatch(getLicencesCount()),
});

export default connect(null, mapDispatchToProps)(SingleLicenceUsers);
