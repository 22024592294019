import { API, getTransAssistUrl, hasPermission } from 'services';
import querystring from 'querystring';
import LogRocket from 'logrocket';
import Cookies from 'js-cookie';
import Moment from 'moment';
import 'moment/locale/pl';
import * as Sentry from '@sentry/react';

import {
    USER_SUCCESS,
    USER_REQUEST,
    USER_ERROR,
    USER_LOGOUT,
    USER_LOG_IN_REQUEST,
    SET_USER_NOT_LOGGED_IN,
    ACTIVATION_SUCCESS,
    ACTIVATION_ERROR,
    ACTIVATION_REQUEST,
} from './constants';

const cookiesSettings = {
    secure: window.location.hostname !== 'localhost',
    domain: window.location.hostname !== 'localhost' ? 'transassist.eu' : 'localhost',
    sameSite: 'strict',
};

export function startUserRequest() {
    return { type: USER_REQUEST };
}

export function startActivationRequest() {
    return { type: ACTIVATION_REQUEST };
}

export function startLogInRequest() {
    return { type: USER_LOG_IN_REQUEST };
}

export function userSuccess(payload, permissions = []) {
    return { type: USER_SUCCESS, payload, permissions };
}

export function userError(payload) {
    return { type: USER_ERROR, payload };
}

export function setUserNotLoggedIn(payload) {
    return { type: SET_USER_NOT_LOGGED_IN, payload };
}

export function userActivated() {
    return { type: ACTIVATION_SUCCESS };
}

export function activationError(payload) {
    return { type: ACTIVATION_ERROR, payload };
}

export function userLogout() {
    return { type: USER_LOGOUT };
}

export function getUser(checkToken = true) {
    return (dispatch) => {
        dispatch(startUserRequest());

        if (checkToken) {
            let token = Cookies.get('token'); /* eslint-disable-line */
            if (!token) {
                return dispatch(setUserNotLoggedIn());
            }

            API.defaults.headers.Authorization = `Bearer ${token}`;
        }

        return API.get('me').then((response) => {
            const responseData = response.data.data;
            const permissions = responseData.permissions
                ? responseData.permissions : [];
            if (permissions.length === 0
                || !hasPermission('admin_panel_manage', responseData.permissions)) {
                if (response.status !== 200) {
                    return dispatch(userError('error'));
                }
                window.location.href = getTransAssistUrl();
            }
            const canUpdateCompany = hasPermission('my_company_update_data', permissions);
            if (canUpdateCompany && responseData.company_nip === null) {
                window.location.href = getTransAssistUrl();
            }
            if (typeof responseData.translations !== 'undefined') {
                localStorage.setItem('translations', JSON.stringify(responseData.translations));
            }
            if (typeof responseData.user.language !== 'undefined') {
                localStorage.setItem('language', responseData.user.language);
                if (responseData.user.language === 'pl') {
                    Moment.locale('pl');
                    document.documentElement.lang = 'pl';
                } else {
                    Moment.locale('en');
                    document.documentElement.lang = 'en';
                }
            }
            if (typeof responseData.user.id !== 'undefined') {
                localStorage.setItem('user_id', responseData.user.id);
            }
            try {
                LogRocket.identify(responseData.user.id, {
                    name: `${responseData.user.name} ${responseData.user.surname}`,
                    email: responseData.user.email,

                    // Add your own custom user variables here, ie:
                    companyID: responseData.user.company_id,
                });
            } catch (e) {
                console.error(e);
            }
            try {
                Sentry.setUser({ email: responseData.user.email });
            } catch (e) {
                console.error(e);
            }
            return dispatch(userSuccess(responseData.user, permissions));
        }).catch((error) => dispatch(userError(error)));
    };
}

export function logIn(email, password, remember = false) {
    return (dispatch) => {
        dispatch(startLogInRequest());
        const authData = {
            username: email,
            password,
            grant_type: 'password',
            client_secret: 'p3TCxwCoTaeErbmQpBdvzSNXFvTSNOMfltliSDfX',
            client_id: '3',
            scope: '*',
        };
        const lang = localStorage.getItem('language') ?? 'en';

        const cookiesSettingsToUse = { ...cookiesSettings };

        if (remember) {
            cookiesSettingsToUse.expires = 730;
        }

        return API.post(`${lang}/first-party/token`, querystring.stringify(authData))
            .then((response) => {
                if (response.status !== 200) {
                    return dispatch(userError(response));
                }
                const token = response.data.access_token;
                Cookies.set('token', token, cookiesSettingsToUse);
                localStorage.setItem('language', response.data.user_data.language);
                if (response.data.user_data.language === 'pl') {
                    Moment.locale('pl');
                    document.documentElement.lang = 'pl';
                } else {
                    Moment.locale('en');
                    document.documentElement.lang = 'en';
                }
                API.defaults.headers.Authorization = `Bearer ${token}`;

                return dispatch(getUser(false));
            })
            .catch((error) => dispatch(userError(error)));
    };
}

export function logOut() {
    return (dispatch) => API.post('/logout')
        .then(() => {
            delete API.defaults.headers.Authorization;
            // localStorage.removeItem('token');
            Cookies.remove('token', cookiesSettings);
            localStorage.removeItem('active_chat');
            localStorage.removeItem('user_id');

            return dispatch(userLogout());
        })
        .catch((error) => dispatch(userError(error)));
}

export function activateUser(token = null) {
    return (dispatch) => {
        if (!token) {
            return dispatch(activationError());
        }
        Cookies.remove('token', cookiesSettings);
        dispatch(startActivationRequest());

        return API.get(`/guest/verify_email/${token}`)
            .then((response) => {
                console.log(response);
                if (typeof response.data.access_token !== 'undefined') {
                    const accessToken = response.data.access_token;
                    Cookies.set('token', accessToken, cookiesSettings);
                    API.defaults.headers.Authorization = `Bearer ${accessToken}`;
                    dispatch(userActivated());
                    return dispatch(getUser(false));
                }
                return dispatch(userActivated());
            })
            .catch((error) => {
                console.error(error.message);
                return dispatch(activationError(error));
            });
    };
}
