import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import LanguageSwitcher from 'stateful/LanguageSwitcher';
import HeaderMenu from './components/HeaderMenu';
import HeaderUser from './components/HeaderUser';
import './styles.scss';

const Header = ({ signedIn }) => (
    <div className="Header">
        <div className="Header__inner">
            <NavLink to="/" className="Header__logo">
                <img
                    width={158}
                    height={64}
                    src="/images/logo.svg"
                    alt="Trans Assist - Logo"
                    className="Header__logo--img Header__logo--full"
                />
                <img
                    width={32}
                    height={36}
                    src="/images/sygnet.svg"
                    alt="Trans Assist - Logo"
                    className="Header__logo--img Header__logo--mobile"
                />
            </NavLink>
            {signedIn && <HeaderMenu />}
            <LanguageSwitcher />
            {signedIn && <HeaderUser />}
        </div>
    </div>
);

Header.propTypes = {
    signedIn: PropTypes.bool,
};

Header.defaultProps = {
    signedIn: false,
};

export default Header;
