export default function classNames(...classes) {
    let result = '';

    for (let i = 0; i < classes.length; i += 1) {
        if (classes[i]) {
            result += (result ? ' ' : '') + classes[i];
        }
    }

    return result;
}
