const translations = (key) => {
    try {
        const strings = localStorage.getItem('translations');
        const parsedStrings = JSON.parse(strings);

        if (typeof parsedStrings[key] !== 'undefined') {
            return parsedStrings[key];
        }

        if (typeof key !== 'undefined') {
            return key;
        }
    } catch (e) {
        console.error(e);
        return '';
    }

    return '';
};

export default translations;
