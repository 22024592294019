import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Form, Formik, Field, ErrorMessage
} from 'formik';
import { errorHandler, translations } from 'services';
import './styles.scss';
import axios, { CancelToken } from 'axios';
import Button from 'components/Button';
import FormField from 'components/FormField';

const SettingsCancelForm = ({ email }) => {
    const cancelToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formResponse, setFormResponse] = useState('');
    const name = 'dlaczego_zdecydowales_sie_usunac_subskrybcje_';
    const defaultValues = {
        informacje: '',
    };
    defaultValues[name] = [];

    const handleSubmit = (values) => {
        setIsLoading(true);
        cancelToken.current = CancelToken.source();
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const valuesString = [];
        values[name].forEach((val) => {
            valuesString.push(translations(`front.admin.settings.tab3.question${parseInt(val, 10) + 1}`));
        });

        const fields = [{
            name: 'email',
            value: email,
        }, {
            name,
            value: valuesString.join(';'),
        }];

        if (values.informacje && values.informacje.length) {
            fields.push(
                {
                    name: 'informacje',
                    value: values.informacje,
                }
            );
        }

        instance.post('https://api.hsforms.com/submissions/v3/integration/submit/8893891/55c7b558-6c7e-436b-a863-455a6bab1041', JSON.stringify({
            fields,
            context: {
                pageUri: window.location.host,
                pageName: 'Strona usuwania konta'
            }
        }), {
            cancelToken: cancelToken.current.token,
        }).then((response) => {
            console.log(response);
            if (response.data.inlineMessage && response.data.inlineMessage.length) {
                setFormResponse(response.data.inlineMessage);
            }
            setIsLoading(false);
        })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoading(false);
                    setError(err);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="SettingsCancelForm">
            {error && <ErrorMessage name={error} />}
            {formResponse.length > 0 && (<div className="SettingsCancelForm__message">{formResponse}</div>)}
            <Formik initialValues={defaultValues} onSubmit={(values) => { handleSubmit(values); }}>
                {({ values }) => {
                    const isDisabled = values[name].length === 0;

                    return (
                        <Form className="SettingsCancelForm__form">
                            <div className="Settings__header" style={{ marginBottom: '46px' }}>{translations('front.admin.settings.tab3.header1')}</div>
                            {['0', '1', '2', '3', '4', '5'].map((elm) => (
                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                <label className="SettingsCancelForm__label" key={elm}>
                                    <Field
                                        className="SettingsCancelForm__checkbox"
                                        type="checkbox"
                                        name={name}
                                        value={elm}
                                    />
                                    <span className="SettingsCancelForm__label__copy">{translations(`front.admin.settings.tab3.question${parseInt(elm, 10) + 1}`)}</span>
                                </label>
                            ))}
                            <div className="Settings__header" style={{ marginBottom: '40px', marginTop: '52px' }}>{translations('front.admin.settings.tab3.header2')}</div>
                            <FormField
                                placeholder={translations('front.admin.settings.tab3.comments')}
                                name="informacje"
                                type="textarea"
                                className="SettingsCancelForm__textarea"
                            />
                            <Button
                                disabled={isDisabled}
                                style={{ minWidth: '160px' }}
                                type="submit"
                                withLoader
                                theme="dangerous"
                                loading={isLoading}
                            >
                                {translations('front.general.delete')}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

SettingsCancelForm.propTypes = {
    email: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        email: user.user.email,
    };
};

export default connect(mapStateToProps, null)(SettingsCancelForm);
