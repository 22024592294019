import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { CancelToken } from 'axios';
import {
    API, classNames, errorHandler, translations
} from 'services';
import { Field, Form, Formik } from 'formik';
import Error from 'components/Error';
import Avatar from 'components/Avatar';
import SmallLoader from 'components/SmallLoader';
import Button from 'components/Button';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import qs from 'qs';
import { getLicencesCount } from 'data/licencesCount/actions';

const SingleLicenceAssign = ({ getLicences }) => {
    const { type } = useParams();
    const history = useHistory();
    const cancelToken = useRef(null);
    const perPage = 50;
    const reference = useRef(null);
    const timer = useRef(0);
    const [users, setUsers] = useState([]);
    const [ids, setIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [assignVisible, setAssignVisible] = useState(false);
    const [assingLoading, setAssignLoading] = useState(false);
    const initialValues = {
        users: [],
    };

    const handleAssign = () => {
        if (reference.current && reference.current.values) {
            if (reference.current.values.users && reference.current.values.users.length) {
                const vals = {
                    user_ids: reference.current.values.users,
                    plan_id: type,
                };
                setAssignLoading(true);
                setError(false);

                API.post('/admin-panel/subscriptions/assign-users-multi', qs.stringify(vals))
                    .then(() => {
                        setAssignLoading(false);
                        getLicences();
                        const event = new Event('ta-users-to-reload');
                        document.dispatchEvent(event);
                        history.push(`/licences/${type}`);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setAssignLoading(false);
                            setError(err);
                        });
                    });
            }
        }
    };

    const handleScroll = useCallback(() => {
        const doc = document.getElementById('single-licence-assign-inner');
        if (doc.scrollTop === (doc.scrollHeight - doc.offsetHeight)) {
            if (currentPage < maxPage && !isLoading) {
                setIsLoading(true);
                cancelToken.current = CancelToken.source();
                setError(false);
                API.get(`/users?page[size]=${perPage}&page[number]=${currentPage + 1}`, {
                    cancelToken: cancelToken.current.token,
                })
                    .then((response) => {
                        if (response.status === 200
                            && response.data.data && response.data.data.length) {
                            setUsers((oldUsers) => oldUsers.concat(response.data.data));
                            const idsToSet = [];
                            response.data.data.forEach((user) => {
                                idsToSet.push(`${user.id}`);
                            });
                            setIds((oldIds) => oldIds.concat(idsToSet));
                        }
                        if (response.data.meta) {
                            setCurrentPage(response.data.meta.current_page);
                            setMaxPage(response.data.meta.last_page);
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setError(err);
                            setIsLoading(false);
                        });
                    });
            }
        }
    }, [currentPage, isLoading, maxPage]);

    const handleChange = () => {
        timer.current = setTimeout(() => {
            try {
                if (reference.current.values.users.length) {
                    setAssignVisible(true);
                } else {
                    setAssignVisible(false);
                }
            } catch (e) {
                setAssignVisible(false);
                console.error(e);
            }
        }, 50);
    };

    const triggerLine = (id) => {
        if (reference.current.values.users.includes(id)) {
            const newArray = [];
            reference.current.values.users.forEach((user) => {
                if (user !== id) {
                    newArray.push(user);
                }
            });
            reference.current.setFieldValue('users', newArray);
        } else {
            const newArray = [...reference.current.values.users, id];
            reference.current.setFieldValue('users', newArray);
        }
        handleChange();
    };

    const handleToggle = (values) => {
        if (reference.current) {
            if (values.users.length > 0) {
                if (values.users.length !== users.length) {
                    reference.current.setFieldValue('users', ids);
                } else {
                    reference.current.setFieldValue('users', []);
                }
            } else {
                reference.current.setFieldValue('users', ids);
            }
            handleChange();
        }
    };

    useEffect(() => {
        const source = CancelToken.source();
        API.get(`/users?page[size]=${perPage}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                if (response.status === 200 && response.data.data && response.data.data.length) {
                    setUsers(response.data.data);
                    const idsToSet = [];
                    response.data.data.forEach((user) => {
                        idsToSet.push(`${user.id}`);
                    });
                    setIds(idsToSet);
                }
                setIsLoading(false);
                if (response.data.meta) {
                    if (response.data.meta.last_page > maxPage) {
                        setMaxPage(response.data.meta.last_page);
                        handleScroll();
                    }
                }
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.getElementById('single-licence-assign-inner').addEventListener('scroll', handleScroll);

        return () => {
            document.getElementById('single-licence-assign-inner').removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
    }, []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="SingleLicenceAssign">
            <NavLink to={`/licences/${type}`} exact className="SingleLicenceAssign__close">{translations('front.general.close')}</NavLink>
            <div className="SingleLicenceAssign__inner" id="single-licence-assign-inner">
                {error && <Error errors={error} />}
                <Formik
                    innerRef={reference}
                    initialValues={initialValues}
                    onSubmit={() => {}}
                >
                    {({ values }) => (
                        <Form onChange={() => { handleChange(); }}>
                            {users.length > 0 && (
                                <div className="table-outer">
                                    <table className="table">
                                        <thead className="table__head">
                                            <tr>
                                                <th scope="col" className="table__checkbox">
                                                    <div className="table__th">
                                                        {users.length > 0 && (
                                                            <button
                                                                type="button"
                                                                className={classNames(
                                                                    'checkbox-button',
                                                                    (values.users.length === users.length) && 'active'
                                                                )}
                                                                onClick={() => {
                                                                    handleToggle(values);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </th>
                                                <th scope="col" className="table__subs">
                                                    <div className="table__th">
                                                        {translations('front.admin.columns.name')}
                                                    </div>
                                                </th>
                                                <th scope="col" className="table__subs">
                                                    <div className="table__th">
                                                        {translations('front.admin.columns.email')}
                                                        <Button
                                                            type="button"
                                                            disabled={(!assignVisible)
                                                        || isLoading || assingLoading}
                                                            onClick={() => { handleAssign(); }}
                                                            withLoader
                                                            theme="blue"
                                                            loading={assingLoading}
                                                        >
                                                            {translations('front.admin.assign_btn')}
                                                        </Button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table__body">
                                            {users.map((user) => (
                                                <tr
                                                    key={user.id}
                                                    onClick={() => {
                                                        if (user.current_subscription_id !== type) {
                                                            triggerLine(`${user.id}`);
                                                        }
                                                    }}
                                                    style={{ cursor: user.current_subscription_id !== type ? 'pointer' : 'default' }}
                                                >
                                                    <td className="table__checkbox">
                                                        {user.current_subscription_id !== type
                                                            && (
                                                                <>
                                                                    <Field
                                                                        checked={values.users.includes(`${user.id}`)}
                                                                        name="users"
                                                                        type="checkbox"
                                                                        className="checkbox"
                                                                        value={user.id}
                                                                    />
                                                                    <span className="checkbox-span" />
                                                                </>
                                                            )}
                                                        {user.current_subscription_id === type && <span className="fake-inactive-checkbox" />}
                                                    </td>
                                                    <td className="table__name">
                                                        <div className="table__name__wrapper">
                                                            <Avatar
                                                                width={48}
                                                                height={48}
                                                                image={user.avatar && user.avatar.download_url ? user.avatar.download_url : ''}
                                                            />
                                                            {user.name}
                                                            {' '}
                                                            {user.surname}
                                                        </div>
                                                    </td>
                                                    <td className="table__email">{user.email}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
                {(!isLoading && users.length === 0) && (
                    <div className="SingleLicenceAssign__no-users">
                        {translations('front.admin.no_basic_users')}
                        {' '}
                        <a
                            href="https://www.transassist.eu/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {translations('front.admin.no_basic_users_create')}
                        </a>
                    </div>
                )}
                {isLoading && (
                    <div className="SingleLicenceUsers__loading">
                        <SmallLoader dark />
                    </div>
                )}
            </div>
        </div>
    );
};

SingleLicenceAssign.propTypes = {
    getLicences: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getLicences: () => dispatch(getLicencesCount()),
});

export default connect(null, mapDispatchToProps)(SingleLicenceAssign);
