import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SmallLoader = ({ dark }) => {
    const img1 = dark ? '/images/loader-circle1-dark.svg' : '/images/loader-circle1.svg';
    const img2 = dark ? '/images/loader-circle2-dark.svg' : '/images/loader-circle2.svg';
    return (
        <div className="SmallLoader">
            <img src={img1} alt="" className="SmallLoader__circle SmallLoader__circle1" />
            <img src={img2} alt="" className="SmallLoader__circle SmallLoader__circle2" />
        </div>
    );
};

SmallLoader.propTypes = {
    dark: PropTypes.bool,
};

SmallLoader.defaultProps = {
    dark: false,
};

export default SmallLoader;
