import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import {
    API, errorHandler, translations
} from 'services';
import './styles.scss';
import { CancelToken } from 'axios';
import Error from 'components/Error';
import SmallLoader from 'components/SmallLoader';
import Moment from 'moment';

const PaymentsInvoices = () => {
    const cancelToken = useRef(null);
    const cancelTokenPdf = useRef(null);
    const perPage = 50;
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [offset, setOffset] = useState(null);
    const [pdfsLoading, setPdfsLoading] = useState(null);

    const handleDownload = (invoiceId) => {
        setPdfsLoading(invoiceId);
        cancelTokenPdf.current = CancelToken.source();
        API.get(`/admin-panel/invoices/${invoiceId}/pdf`, {
            responseType: 'blob',
            cancelToken: cancelTokenPdf.current.token,
        })
            .then((response) => {
                setPdfsLoading(null);
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' }
                );
                const fileURL = URL.createObjectURL(file);
                // Open the URL on new Window
                window.open(fileURL);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setPdfsLoading(null);
                    setError(err);
                });
            });
    };

    const handleScroll = useCallback(() => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && offset) {
            if (!isLoading) {
                setIsLoading(true);
                cancelToken.current = CancelToken.source();
                API.get(`/admin-panel/invoices?page[size]=${perPage}&offset=${offset}`, {
                    cancelToken: cancelToken.current.token,
                })
                    .then((response) => {
                        if (response.status === 200
                            && response.data.data && response.data.data.length) {
                            setInvoices((old) => old.concat(response.data.data));
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        if (response.data._meta && response.data._meta.offset) {
                            // eslint-disable-next-line no-underscore-dangle
                            setOffset(response.data._meta.offset);
                        } else {
                            setOffset(null);
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setError(err);
                            setIsLoading(false);
                        });
                    });
            }
        }
    }, [isLoading, offset]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        setIsLoading(true);
        cancelToken.current = CancelToken.source();
        API.get(`/admin-panel/invoices?page[size]=${perPage}`, {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    setInvoices(response.data.data);
                }
                // eslint-disable-next-line no-underscore-dangle
                if (response.data._meta && response.data._meta.offset) {
                    // eslint-disable-next-line no-underscore-dangle
                    setOffset(response.data._meta.offset);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
    }, []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
        if (cancelTokenPdf.current) {
            cancelTokenPdf.current.cancel();
        }
    }, []);

    return (
        <div className="PaymentsInvoices">
            {error && <Error errors={error} />}
            <div className="table-outer">
                {invoices.length === 0 && !isLoading && (
                    <div className="empty-table">{translations('front.admin.empty.invoices')}</div>
                )}
                {invoices.length > 0 && (
                    <table className="table">
                        <thead className="table__head">
                            <tr>
                                <th scope="col" className="table__subs" style={{ width: '25%' }}>
                                    <div className="table__th">
                                        {translations('front.admin.payments.invoice_number')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs" style={{ width: '25%' }}>
                                    <div className="table__th">
                                        {translations('front.admin.payments.invoice_date')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs" style={{ width: '25%' }}>
                                    <div className="table__th">
                                        {translations('front.admin.payments.invoice_amount')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs" style={{ width: '25%' }}>
                                    <div className="table__th">
                                        {translations('front.admin.payments.invoice_download')}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table__body">
                            {invoices.map((invoice) => (
                                <tr key={invoice.id}>
                                    <td>
                                        {invoice.id}
                                    </td>
                                    <td>
                                        {Moment(invoice.date * 1000).format('LL')}
                                    </td>
                                    <td>
                                        {(invoice.total / 100).toFixed(2)}
                                        €
                                    </td>
                                    <td>
                                        <div className="PaymentsInvoices__pdf">
                                            {pdfsLoading === invoice.id ? (
                                                <SmallLoader dark />
                                            ) : (
                                                <button
                                                    type="button"
                                                    disabled={pdfsLoading}
                                                    onClick={() => { handleDownload(invoice.id); }}
                                                    className="PaymentsInvoices__download"
                                                >
                                                    PDF
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {isLoading && (
                <div className="PaymentsInvoices__loading">
                    <SmallLoader dark />
                </div>
            )}
        </div>
    );
};

export default PaymentsInvoices;
