const translations = {
    loading: 'Loading',
    loading_data: 'Loading data...',
    validate_email: 'This is not a valid e-mail address',
    validate_required: 'This field is required',
    validate_the_same: 'Both passwords must have the same value',
    sign_in: 'Log in',
    sign_up__invitation: 'Don\'t have an account?',
    sign_up: 'Sign up',
    forgot_password: 'Forgot your password?',
    error_occured: 'An error occured!',
    activation_token_broken: 'Activation token is broken.',
    return_to_login: 'Go back to login page',
    activation_success: 'Thanks! Your account is active now.',
    forgot_password_submit: 'Reset password',
    password: 'Password',
    password_confirmation: 'Confirm password',
    email: 'E-mail',
    remember_me: 'Remember me',
    welcome: 'Welcome to',
    log_in_to_access: 'Log in to access your account',
    password_forgot_message: 'Can\'t log in? Please enter your e-mail address to reset your password!',
    back_to: 'Back to',
    sign_in_page: 'sign in',
    select: 'Select...',
    go_back_ta: 'Go back to Trans Assist',
};
export default translations;
