import React from 'react';
import qs from 'qs';
import Page from 'components/Page';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames, translations } from 'services';
import './style.scss';

const PaymentConfirmation = () => {
    const { search } = useLocation();
    const { state } = qs.parse(search);
    let status = 'error';
    let width = 80;
    let height = 80;

    if (state === 'succeeded') {
        status = 'success';
    } else if (state === 'acknowledged') {
        status = 'progress';
        width = 98;
        height = 98;
    }

    return (
        <div className="PaymentConfirmation">
            <Page>
                <div className="PaymentConfirmation__container">
                    <div className="PaymentConfirmation__icon-container">
                        <img
                            width={width}
                            height={height}
                            src={`/images/payment-status/${status}.svg`}
                            className="PaymentConfirmation__icon"
                            alt=""
                        />
                    </div>
                    <h1 className={classNames('PaymentConfirmation__header', status)}>{translations(`front.admin.payment-status.headers.${status}`)}</h1>
                    <p className="PaymentConfirmation__copy">
                        {translations(`front.admin.payment-status.${status === 'error' ? 'error' : 'thanks'}`)}
                        <br />
                        {translations(`front.admin.payment-status.payment-${status}`)}
                    </p>
                    <div className="PaymentConfirmation__center">
                        <NavLink to="/" exact className="PaymentConfirmation__btn">{translations('front.general.close')}</NavLink>
                    </div>
                </div>
            </Page>
        </div>
    );
};

export default PaymentConfirmation;
