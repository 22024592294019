import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { getUser } from 'data/user/actions';

import SmallLoader from 'components/SmallLoader';
import { classNames } from 'services';
import Header from 'components/Header';
import Licences from 'pages/Licences';
import SingleLicence from 'pages/SingleLicence';
import Checkout from 'pages/Checkout';
import NotFound from 'pages/NotFound';
import Payments from 'pages/Payments';
import Settings from 'pages/Settings';
import PaymentConfirmation from 'pages/PaymentConfirmation';
import Auth from 'stateful/Auth';

import './App.scss';
import Footer from './components/Footer';
import BackToTransassist from './components/BackToTransassist';
import GeneralError from './components/GeneralError';
import EnvSwitcher from './components/EnvSwitcher';

const generalErrorFallback = <GeneralError />;


const App = ({
    getCurrentUser, user,
}) => {
    useEffect(() => {
        getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { loading, isLoggedIn } = user;

    if (loading) {
        return (
            <Sentry.ErrorBoundary fallback={generalErrorFallback}>
                <div className={classNames('App', 'is-loading')}><SmallLoader dark /></div>
            </Sentry.ErrorBoundary>
        );
    }

    if (!isLoggedIn) {
        return (
            <Sentry.ErrorBoundary fallback={generalErrorFallback}>
                <div className={classNames('App', 'signed-out')}>
                    <Router>
                        <Header />
                        <Auth />
                    </Router>
                    <EnvSwitcher />
                </div>
            </Sentry.ErrorBoundary>
        );
    }

    return (
        <Sentry.ErrorBoundary generalErrorFallback>
            <div className={classNames('App', isLoggedIn && 'logged-in')}>
                <Router>
                    <Header signedIn />
                    <BackToTransassist />
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to="/licences" />
                        </Route>
                        <Route path="/licences" component={Licences} exact />
                        <Route path="/licences/:type(basic|standard|premium)" component={SingleLicence} exact />
                        <Route path="/licences/:type(basic|standard|premium)/assign" exact>
                            <SingleLicence isAssign />
                        </Route>
                        <Route path="/licences/:type(standard|premium)/subscriptions" exact>
                            <SingleLicence isSubscriptions />
                        </Route>
                        <Route path="/licences/checkout" exact>
                            <Redirect to="/checkout/premium" />
                        </Route>
                        <Route path="/licences/checkout/:type(standard|premium)" component={Checkout} exact />
                        <Route path="/payments" component={Payments} exact />
                        {false && <Route path="/settings" component={Settings} exact />}
                        <Route path="/payment-confirmation" component={PaymentConfirmation} exact />
                        <Route><NotFound /></Route>
                    </Switch>
                    <Footer />
                </Router>
                <EnvSwitcher />
            </div>
        </Sentry.ErrorBoundary>
    );
};

App.propTypes = {
    getCurrentUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
        loading: PropTypes.bool,
        user: PropTypes.object,
        error: PropTypes.any,
        isLoggedIn: PropTypes.bool,
        activated: PropTypes.bool,
        activationError: PropTypes.any,
        activationLoading: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
