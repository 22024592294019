import React, { useState } from 'react';
import { Field, useFormikContext, useField } from 'formik';
import PropTypes from 'prop-types';

import { classNames } from 'services';

import ErrorMessage from 'components/ErrorMessage';

import './styles.scss';

const FormField = ({
    label,
    name,
    isRequired,
    isSelect,
    isAsyncCreatable,
    isPhone,
    isDateTimePicker,
    copy,
    ...props
}) => {
    const { errors, touched } = useFormikContext();
    const [formikField] = useField(name);
    const error = errors[name];
    const touch = touched[name];
    const hasError = error && touch;
    const [hasValue, setHasValue] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const handleKeyUp = (e) => {
        if (e && e.target && e.target.value) {
            setHasValue(true);
        } else {
            setHasValue(false);
        }
    };

    const fieldRender = (
        <Field
            onKeyUp={(e) => handleKeyUp(e)}
            onBlur={(e) => {
                formikField.onBlur(e);
                setHasFocus(false);
            }}
            onFocus={() => {
                setHasFocus(true);
            }}
            className="form-field__field"
            name={name}
            id={`field-${name}`}
            /* eslint-disable-next-line react/destructuring-assignment,react/prop-types */
            component={props.type === 'textarea' ? 'textarea' : 'input'}
            {...props}
        />
    );

    return (
        <div className={classNames('form-field', hasError && 'form-field--error', label && 'has-label', hasValue && 'has-value', hasFocus && 'has-focus')}>
            {label && (
                <div className="form-field__label">
                    <span className="form-field__label--copy" title={label}>{label}</span>
                    {isRequired && <span className="form-field__label--required" />}
                </div>
            )}
            {
                fieldRender
            }
            {
                copy.length > 0 ? (
                    <label
                        htmlFor={`field-${name}`}
                        className="form-field__field-copy"
                    >
                        {copy}
                    </label>
                ) : ''
            }
            {hasError && <ErrorMessage error={error} />}
        </div>
    );
};

FormField.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    copy: PropTypes.string,
    isSelect: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
    ]),
    isAsyncCreatable: PropTypes.bool,
    isDateTimePicker: PropTypes.bool,
    isPhone: PropTypes.bool,
};

FormField.defaultProps = {
    label: null,
    error: null,
    isRequired: false,
    isSelect: false,
    isDateTimePicker: false,
    copy: '',
    isAsyncCreatable: false,
    isPhone: false,
};

export default FormField;
