import React from 'react';
import './styles.scss';

const GeneralError = () => (
    <div className="ErrorBoundary">
        <div className="ErrorBoundary-inner">
            <div className="ErrorBoundary__content">
                <div className="ErrorBoundary__header">Ups!</div>
                <div className="ErrorBoundary__copy">
                    Wystąpił problem
                    <br />
                    Prosimy - spróbuj ponownie.
                </div>
                <div className="ErrorBoundary__reload-outer">
                    <button
                        className="ErrorBoundary__reload"
                        onClick={() => { window.location.reload(false); }}
                        type="button"
                    >
                        Odśwież stronę
                    </button>
                </div>
            </div>
            <img
                src="/images/error.png"
                width={533}
                height={607}
                alt=""
                className="ErrorBoundary__image"
            />
        </div>
    </div>
);

export default GeneralError;
