import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Language = ({ clickHandler, currentLang }) => (
    <button
        type="button"
        onClick={() => { clickHandler(); }}
        className="Language"
    >
        <img src={currentLang === 'pl' ? '/images/lang/en.png' : '/images/lang/pl.png'} width={22} className="Language__flag" alt="" />
        <span className="Language__copy">
            {currentLang === 'pl' ? 'English' : 'Polish'}
        </span>
    </button>
);

Language.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    currentLang: PropTypes.string.isRequired,
};

export default Language;
