import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'data/store';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import API from './services/API';


import './fonts/Inter-Black.ttf';
import './fonts/Inter-Black.eot';
import './fonts/Inter-Black.woff';
import './fonts/Inter-Black.woff2';
import './fonts/Inter-Bold.ttf';
import './fonts/Inter-Bold.eot';
import './fonts/Inter-Bold.woff';
import './fonts/Inter-Bold.woff2';
import './fonts/Inter-ExtraBold.ttf';
import './fonts/Inter-ExtraBold.eot';
import './fonts/Inter-ExtraBold.woff';
import './fonts/Inter-ExtraBold.woff2';
import './fonts/Inter-ExtraLight.ttf';
import './fonts/Inter-ExtraLight.eot';
import './fonts/Inter-ExtraLight.woff';
import './fonts/Inter-ExtraLight.woff2';
import './fonts/Inter-Light.ttf';
import './fonts/Inter-Light.eot';
import './fonts/Inter-Light.woff';
import './fonts/Inter-Light.woff2';
import './fonts/Inter-Medium.ttf';
import './fonts/Inter-Medium.eot';
import './fonts/Inter-Medium.woff';
import './fonts/Inter-Medium.woff2';
import './fonts/Inter-Regular.ttf';
import './fonts/Inter-Regular.eot';
import './fonts/Inter-Regular.woff';
import './fonts/Inter-Regular.woff2';
import './fonts/Inter-SemiBold.ttf';
import './fonts/Inter-SemiBold.eot';
import './fonts/Inter-SemiBold.woff';
import './fonts/Inter-SemiBold.woff2';
import './fonts/Inter-Thin.ttf';
import './fonts/Inter-Thin.eot';
import './fonts/Inter-Thin.woff';
import './fonts/Inter-Thin.woff2';

import './index.scss';
import App from './App';
import { setUserNotLoggedIn } from './data/user/actions';

const isDev = ['admin-tests-dev.transassist.eu', 'admin-tests-prod.transassist.eu'].includes(window.location.host);
const isStaging = window.location.host === 'admin-staging.transassist.eu';
// eslint-disable-next-line no-nested-ternary
const environment = isDev ? 'development' : (isStaging ? 'staging' : 'production');

if (isDev) {
    LogRocket.init('xfcsam/frontend-development');
    setupLogRocketReact(LogRocket);
} else if (isStaging) {
    LogRocket.init('xfcsam/frontend-staging-vkvw3');
    setupLogRocketReact(LogRocket);
} else {
    const tagManagerArgs = {
        gtmId: 'GTM-MB52BVW'
    };

    TagManager.initialize(tagManagerArgs);


    LogRocket.init('xfcsam/frontend-k2tht');
    setupLogRocketReact(LogRocket);
}

Sentry.init({
    dsn: 'https://8f427935f63c43d1ad57746168cee8a8@o926428.ingest.sentry.io/5877403',
    integrations: [new Integrations.BrowserTracing()],
    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // beforeSend(event) {
    //     if (typeof LogRocket !== 'undefined') {
    //         const logRocketSession = LogRocket.sessionURL;
    //         if (logRocketSession !== null) {
    //             // eslint-disable-next-line no-param-reassign
    //             event.extra.LogRocket = logRocketSession;
    //             return event;
    //         }
    //     }
    //     return event;
    // },
});


const { dispatch } = store;
API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status } = error.response;
            if (status === 401) {
                Cookies.remove('token');
                dispatch(setUserNotLoggedIn());
            }
        }
        return Promise.reject(error);
    }
);

ReactDOM.render(
    (
        // eslint-disable-next-line react/jsx-filename-extension
        <Provider store={store}>
            <App />
        </Provider>
    ),
    document.getElementById('root')
);
