import {
    LICENCES_COUNT_ERROR,
    LICENCES_COUNT_SUCCESS,
    LICENCES_COUNT_REQUEST,
} from './constants';

export const initialGeneralState = {
    licences: [],
    loading: false,
    error: false,
    token: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case LICENCES_COUNT_REQUEST:
        if (state.token !== false) {
            state.token.cancel();
        }
        newState.licences = [];
        newState.loading = true;
        newState.error = false;
        newState.token = action.payload;
        return newState;
    case LICENCES_COUNT_SUCCESS:
        newState.error = false;
        newState.loading = false;
        newState.licences = action.payload;
        return newState;
    case LICENCES_COUNT_ERROR:
        newState.licences = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    default:
        return state;
    }
};
