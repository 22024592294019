import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';
import LicenceStatus from 'components/LicenceStatus';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import {
    API, classNames, errorHandler, translations
} from 'services';
import Moment from 'moment';
import './styles.scss';
import { CancelToken } from 'axios';

const SingleLicenceSubscriptions = () => {
    const { type } = useParams();
    const cancelToken = useRef(null);
    const perPage = 50;
    const [licences, setLicences] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [offset, setOffset] = useState(null);

    const handleScroll = useCallback(() => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && offset) {
            if (!isLoading) {
                setIsLoading(true);
                cancelToken.current = CancelToken.source();
                API.get(`/admin-panel/subscriptions?page[size]=${perPage}&filter[plan_id]=${type}&offset=${offset}`, {
                    cancelToken: cancelToken.current.token,
                })
                    .then((response) => {
                        if (response.status === 200
                            && response.data.data && response.data.data.length) {
                            setLicences((old) => old.concat(response.data.data));
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        if (response.data._meta && response.data._meta.offset) {
                            // eslint-disable-next-line no-underscore-dangle
                            setOffset(response.data._meta.offset);
                        } else {
                            setOffset(null);
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setError(err);
                            setIsLoading(false);
                        });
                    });
            }
        }
    }, [isLoading, offset, type]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        setIsLoading(true);
        cancelToken.current = CancelToken.source();
        API.get(`/admin-panel/subscriptions?page[size]=${perPage}&filter[plan_id]=${type}`, {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    setLicences(response.data.data);
                }
                // eslint-disable-next-line no-underscore-dangle
                if (response.data._meta && response.data._meta.offset) {
                    // eslint-disable-next-line no-underscore-dangle
                    setOffset(response.data._meta.offset);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
    }, [type]);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="SingleLicenceSubscriptions">
            {error && <Error errors={error} />}
            <div className="table-outer">
                {!isLoading && licences.length === 0 && (
                    <div className="empty-table">{translations('front.admin.empty.sub')}</div>
                )}
                {licences.length > 0 && (
                    <table className="table">
                        <thead className="table__head">
                            <tr>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.status')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.subscription')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.klient')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.date')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.qty')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.price')}
                                    </div>
                                </th>
                                <th scope="col" className="table__subs">
                                    <div className="table__th">
                                        {translations('front.admin.subscriptions.date_create')}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table__body">
                            {licences.map((licence) => (
                                <tr key={licence.id}>
                                    <td>
                                        <LicenceStatus status={licence.status} />
                                    </td>
                                    <td>
                                        <div className={classNames('SingleLicenceSubscriptions__plan', `licence-type-${licence.plan_id}`)}>{licence.plan_id}</div>
                                        <div className="SingleLicenceSubscriptions__content">{licence.id}</div>
                                    </td>
                                    <td>
                                        <div className="SingleLicenceSubscriptions__bold">
                                            {licence.shipping_address.first_name}
                                            {' '}
                                            {licence.shipping_address.last_name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="SingleLicenceSubscriptions__bold">
                                            {licence.next_billing_at ? Moment(licence.next_billing_at).format('ll') : ''}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="SingleLicenceSubscriptions__bold">
                                            {licence.plan_quantity}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="SingleLicenceSubscriptions__bold">
                                            {licence.mrr.toFixed(2)}
                                            €
                                        </div>
                                    </td>
                                    <td>
                                        <div className="SingleLicenceSubscriptions__content">
                                            {Moment(licence.created_at).format('LL')}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {isLoading && (
                <div className="SingleLicenceSubscriptions__loading">
                    <SmallLoader dark />
                </div>
            )}
        </div>
    );
};

export default SingleLicenceSubscriptions;
