import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { classNames } from 'services';

const Page = ({ top, children }) => (
    <div className={classNames('Page', top && 'top')}>
        <div className="Page__wrapper">
            {children}
        </div>
    </div>
);

Page.propTypes = {
    top: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Page.defaultProps = {
    top: false,
};

export default Page;
