const isDevEnvironment = () => {
    const devEnvironments = [
        'localhost:3000',
        'app-dev.transassist.eu',
        'app-staging.transassist.eu',
        'admin-dev.transassist.eu',
        'admin-staging.transassist.eu',
    ];
    return devEnvironments.includes(window.location.host);
};

export default isDevEnvironment;
