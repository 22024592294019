import axios from 'axios';
import { isDevEnvironment } from '../index';

const prodUrl = 'https://api.transassist.eu/';
const devUrl = 'https://api-dev.transassist.eu/';
const useDev = isDevEnvironment() && localStorage.getItem('ta_api_version') !== 'prod';

const API = axios.create({
    baseURL: useDev ? devUrl : prodUrl,

    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
    }
});

export default API;
