import React from 'react';
import PropTypes from 'prop-types';
import { classNames, translations } from 'services';
import LicenceLine from 'components/LicenceLine';
import './styles.scss';

const SimpleLicence = ({ type, subscriptions, users }) => {
    const typeToUse = type.length
        ? translations(`front.admin.licences_page.licence_type.${type}`)
        : translations('front.admin.licences_page.licence_count');
    return (
        <div className={classNames('SimpleLicence', type)}>
            <div className="SimpleLicence__copy">{typeToUse}</div>
            <div className="SimpleLicence__limit">
                {users}
                {' '}
                /
                {' '}
                {subscriptions}
            </div>
            <LicenceLine subscriptions={subscriptions} users={users} />
        </div>
    );
};

SimpleLicence.propTypes = {
    type: PropTypes.string,
    subscriptions: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
};

SimpleLicence.defaultProps = {
    type: '',
};

export default SimpleLicence;
