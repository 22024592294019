import React from 'react';
import Button from 'components/Button';
import translations from 'services/translations';
import PropTypes from 'prop-types';
import { logOut } from 'data/user/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Logout = ({ logUserOut }) => {
    const logoutText = translations('authentication.logout');
    const history = useHistory();

    const handleLogout = () => {
        logUserOut();
        history.push('/');
    };

    return (
        <div>
            <Button type="button" theme="logout" onClick={handleLogout}>
                {logoutText}
            </Button>
        </div>
    );
};


Logout.propTypes = {
    logUserOut: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    logUserOut: () => dispatch(logOut()),
});

export default connect(null, mapDispatchToProps)(Logout);
