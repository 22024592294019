import React from 'react';
import PropTypes from 'prop-types';
import { classNames, getTransAssistUrl } from 'services';
import './styles.scss';
import defaultTranslation from 'services/defaultTranslations';

const BackToTransassist = ({ center }) => {
    const lang = localStorage.getItem('language') ?? 'en';
    const translation = defaultTranslation('go_back_ta', lang);
    return (
        <div className="Page__wrapper" style={{ display: 'flex', justifyContent: center ? 'center' : 'flex-start' }}>
            <a href={getTransAssistUrl()} className={classNames('BackToTransAssist', center && 'is-center')}>{translation}</a>
        </div>
    );
};

BackToTransassist.propTypes = {
    center: PropTypes.bool,
};

BackToTransassist.defaultProps = {
    center: false
};

export default BackToTransassist;
